import { NotFound } from '@components/NotFound';
import {
  DeleteRealogramDialog,
  ImageModal,
  MemoModal,
  ProductCandidatesDrawer,
} from '@components/organisms';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { ParcelDivisionModal } from '@components/organisms/parcelDivisionModal/parcelDivisionModal';
import { RealogramInfoDialog } from '@components/organisms/realogramInfoDialog/realogramInfoDialog';
import { useAddFace } from '@components/pages/scannerDetails/hooks/useAddFace';
import { useDeleteRealogramFace } from '@components/pages/scannerDetails/hooks/useDeleteRealogramFace';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useCloneRealogramToPlanogram } from './hooks/useCloneRealogramToPlanogram';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { useInitSelectedRealogram } from './hooks/useInitSelectedRealogram';
import { usePageTitle } from '@hooks/usePageTitle';
import { useProductCandidatesDrawer } from './hooks/useProductCandidatesDrawer';
import { useRealogramAnalyticsData } from '@hooks/useRealogramAnalyticsData';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { useSlideButton } from '@hooks/useSlideButton';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import {
  useDeleteRealogramMutation,
  useDeleteShelfBoardMutation,
  useGetRealogramCandidateQuery,
  useUpdateFaceMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  paths,
  productTags,
  profitTabSales,
  rateValues as rateValuesArr,
  toastMessages,
} from '@utils/const';
import { Format, format } from '@utils/date';
import {
  Refs,
  calculateRealogramStatistics,
  cropProductImage,
  findSelectedItemIndexes,
  getArgs,
  getNewSelectedItem,
  isSelectedItem,
  scrollToCenterOfList,
} from '@utils/realogram';
import { createRateValues } from '@utils/statistics';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import httpStatus from 'http-status';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Crop } from 'react-image-crop';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import {
  Product,
  ProductTag,
  ProfitTab,
  Rate,
  RateValue,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  ProductCandidate as ProductCandidateType,
  RealogramCandidate,
  RealogramCandidateFace,
  RealogramSelectedItem,
  Status,
} from 'types/realogram';
import { ProductTagValue } from 'types/statistics';
import { AddFaceMode } from './fragments/addFaceMode/addFaceMode';
import { Comparison } from './fragments/comparison';
import { RealogramDetail } from './fragments/realogramDetail';
import { useUnknownProductsRevise } from './hooks/useUnknownProductsRevise';
import { timer } from './utils';
import { RealogramDetailHeader } from './fragments/realogramDetailHeader';

dayjs.extend(utc);
dayjs.extend(timezone);

export const ScannerDetails = () => {
  const selectedItem = useAppSelector(selectRealogramSelectedItem);
  const realogramImageElement: HTMLImageElement | null = document.querySelector(
    `[data-image="original-image-${selectedItem?.shotIndex ?? 1}"]`
  );
  const [sessionLastVisitedRealogramListURL] = useSessionStorage(
    'sessionLastVisitedRealogramListURL',
    paths.actuals.viewList
  );
  const listItemRefs = useRef<Refs>(new Map());

  const params = useParams<{ id: string }>();
  const realogramCandidateId = parseInt(params.id ?? '0');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedDirectoryId, lastVisitedRealogramListURL } = useAppSelector(
    (state) => state.Scanner
  );
  const [deleteRealogram] = useDeleteRealogramMutation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isErrorComponent, setIsErrorComponent] = useState(false);
  const [memoModal, setMemoModal] = useState(false);
  const [isDivisionModalOpen, setIsDivisionModalOpen] = useState(false);
  const [productCandidateIds, setProductCandidateIds] = useState<string>();
  const [imageIsLoading, setImageIsLoading] = useState(false); //realogramImageが完全に読み込まれたことを確認するフラグ
  const [referenceImagePreview, setReferenceImagePreview] = useState(false);
  const [selectedProductZoomIn, setSelectedProductZoomIn] = useState<Product>();
  const [moreInfoDialog, setMoreInfoDialog] = useState(false);
  const [productTag, setProductTag] = useState<ProductTag>(productTags[0]);
  const [rateValue, setRateValue] = useState<Rate>(rateValuesArr[1]);
  const [realogramDetailView, setRealogramDetailView] =
    useState<ShelfDetailView>('default');
  const [realogramDetailMode, setRealogramDetailMode] =
    useState<ShelfDetailMode>('default');
  const [face, setFace] = useState<RealogramCandidateFace | undefined>();
  const [deleteShelfBoard] = useDeleteShelfBoardMutation();
  const [isAscending, setIsAscending] = useState(false);
  const { resetComparisonItemModalState } = useComparisonItemModal();
  const { showLicenseModal } = useSharePermissionModal();
  const {
    updateViewQueryParams,
    updateModeQueryParams,
    updateAttributeQueryParams,
    updateAnalyticsQueryParams,
    updateEvaluationQueryParams,
  } = useUpdateUrlQueryParamsOfDetailPages();
  const [status, setStatus] = useState<Status>();
  const { isLarger } = useBreakpoint();
  const isComparedMode = realogramDetailMode === 'comparison';

  const {
    selector,
    operate,
    data: { isLoadingTenant, canReadDemo, isTenantSalesAnalytics },
    searchParams,
  } = useRerenderingDetails();

  const {
    data,
    error: realogramCandidateError,
    isLoading,
    refetch: refetchRealogramCandidate,
    isFetching: isRealogramCandidateFetching,
  } = useGetRealogramCandidateQuery({
    realogramCandidateId,
  });
  const realogramProductsCategories =
    data?.realogram_candidate.store_bay.categories;
  const isBucketType = data?.realogram_candidate.shot_type === 'split_bucket';
  const isProcessed = status === 'processed';
  const isForbidden =
    !!realogramCandidateError &&
    'status' in realogramCandidateError &&
    realogramCandidateError.status === httpStatus.FORBIDDEN;
  const realogramCandidate = data?.realogram_candidate;
  usePageTitle(`${realogramCandidate?.store_bay.name ?? ''} | スキャン結果`);
  const { bayPlanCode } = useBayPlanCodes(realogramCandidate?.store_bay_id);
  const {
    analyticsData,
    refetchRealogramAnalytics,
    analyticsDataTerm,
    displayAnalyticsData,
    disableChangeToPreviousWeek,
    disableChangeToNextWeek,
    displayAnalyticsDataIndex,
    handleNextWeekTerm,
    handlePreviousWeekTerm,
    isLoadingAnalyticsData,
  } = useRealogramAnalyticsData(
    realogramCandidateId,
    !isTenantSalesAnalytics ||
      realogramCandidateId === 0 ||
      (realogramDetailView !== 'profit' && !isComparedMode)
  );
  const { handleCreatePlanogram } = useCloneRealogramToPlanogram({
    realogramCandidate,
    isBucketType,
  });
  const { filteredShelfBoards: shelfBoards, primaryCandidates } =
    useExtractRealogramData(
      realogramDetailView,
      productTag,
      analyticsData?.reports.at(0)?.products ?? [],
      rateValue,
      realogramCandidate?.detail?.products_shelves.shelf_boards
    );
  const [updateFace] = useUpdateFaceMutation();
  const [profitTab, setProfitTab] = useState<ProfitTab>(profitTabSales);
  const [comparisonProfitTabValue, setComparisonProfitTabValue] =
    useState<ProfitTab>(profitTabSales);

  const { realogramStatistics, score, rateValues, productTagValues } =
    useMemo(() => {
      const realogramStatistics = calculateRealogramStatistics(
        realogramCandidate?.detail?.products_shelves.shelf_boards ?? [],
        primaryCandidates?.products
      );

      const rateValues: RateValue[] = createRateValues(
        undefined,
        realogramStatistics
      );
      const productTagValues: ProductTagValue[] = [
        {
          item: 'new_products_first_week',
          value: realogramStatistics?.productFlag.totalNewFacesFirstWeek ?? '',
        },
        {
          item: 'new_products_second_week',
          value: realogramStatistics?.productFlag.totalNewFacesSecondWeek ?? '',
        },
        {
          item: 'base_product',
          value: realogramStatistics?.productFlag.totalMainFaces ?? '',
        },
        {
          item: 'sales_ended',
          value:
            realogramStatistics?.productFlag
              .totalRecomendedToCancelationFaces ?? '',
        },
        {
          item: 'not_3D_scanned',
          value: realogramStatistics?.productFlag.totalNot3DScannedFaces ?? '',
        },
      ];
      return {
        realogramStatistics,
        score: realogramStatistics?.score,
        rateValues,
        productTagValues,
      };
    }, [
      //NOTE: 一度に全ての統計情報を計算する必要があるため、タブによってfilteringされていないshelfBoardsを渡す必要がある
      realogramCandidate?.detail?.products_shelves.shelf_boards,
      primaryCandidates?.products,
    ]);

  const [initRerenderingHeaderStatus, setInitRerenderingHeaderStatus] =
    useState(false); // 初回描画：ヘッダータブ更新フラグ
  const [initRerenderingSelectedProduct, setInitRerenderingSelectedProduct] =
    useState(false); // 初回描画：選択中のアイテム情報更新フラグ
  const [initUpdateUrl, setInitUpdateUrl] = useState(false); // 初回描画：URLの更新フラグ

  const { product } = operate.getSelectedItemOfRealogram(shelfBoards);
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();

  const shelfBoardsToDisplay = useMemo(() => {
    return isBucketType ? shelfBoards : shelfBoards?.slice().reverse();
  }, [isBucketType, shelfBoards]);

  const referenceImage = useMemo(() => {
    if (!realogramImageElement || !selectedItem || !imageIsLoading) return '';
    return cropProductImage(realogramImageElement, selectedItem.item.bbox);
  }, [realogramImageElement, selectedItem, imageIsLoading]);

  const {
    selectedCandidate,
    isDrawerOpen,
    products,
    bottomDrawerOpen,
    productCandidates,
    primaryCandidate,
    productIdFrom,
    isUnknown,
    productCandidatesLength,
    selectedUnknown,
    isLoadingUpdateFace,
    isResetData,
    selectedProductChoice,
    isDisplayProductDetail,
    setIsDrawerOpen,
    handleProductCandidatesDrawerClose,
    reset,
    handleProductClick,
    handleProductChoiceClick,
    handleSpecialProductClick,
    handleChangeCandidateInDrawer,
    handleCloseProductChoice,
    setSelectedProductChoice,
    setIsDisplayProductDetail,
    setSelectedCandidate,
  } = useProductCandidatesDrawer(
    realogramCandidateId,
    refetchRealogramCandidate,
    realogramCandidate,
    productCandidateIds,
    selectedItem
  );

  const {
    isEnableReviseButton,
    isUnknownProductsReviseMode,
    unknownProductsInfo,
    isCheckedRecommendedRevise,
    hasRecommendProducts,
    handleCheckboxChange,
    handleToggleRecommendedRevise,
    setIsUnknownProductsReviseMode,
    handleOnBulkRevise,
    handleResetReviseMode,
  } = useUnknownProductsRevise({
    realogramDetailView,
    shelfBoardsToDisplay,
    realogramCandidateId,
    realogramDetailMode,
  });

  const { shelfBoardIndex, compartmentIndex, faceIndex } = useMemo(() => {
    const indexes = findSelectedItemIndexes(selectedItem, shelfBoards);
    if (!indexes) {
      return {
        shelfBoardIndex: undefined,
        compartmentIndex: undefined,
        faceIndex: undefined,
      };
    }
    return indexes;
  }, [selectedItem, shelfBoards]);

  const { shouldButtonBeDisabled, selectPrevious, selectNext } = useSlideButton(
    isBucketType,
    realogramDetailView,
    updateQueryParameter,
    shelfBoardIndex,
    compartmentIndex,
    faceIndex,
    selectedItem,
    realogramCandidate,
    shelfBoards
  );

  useInitSelectedRealogram({
    selectedItem,
    shelfBoards: shelfBoardsToDisplay,
    isSkip: isComparedMode,

    initSelectedData: {
      isSkipInit: isLoading || isComparedMode || initRerenderingSelectedProduct,
      onInitCompleted: setInitRerenderingSelectedProduct,
    },
  });

  const {
    isAddFaceMode,
    compartmentNumber,
    canNotShowImage,
    shotBboxes,
    realogramImage,
    addFaceModeLabel,
    setCompartmentNumber,
    handleAddFace,
    handleSaveNewFace,
  } = useAddFace(realogramCandidateId, shelfBoards);

  // ブラウザバックなどの操作時に発火する
  const updateStateByBrowserOperated = () => {
    setInitRerenderingHeaderStatus(false);
    setInitRerenderingSelectedProduct(false);
  };

  useBrowserOperate(updateStateByBrowserOperated);

  const { isNotEnable: isCanNotEditProductAndCompartment } =
    useGetRealogramPermission({
      action: 'update',
      realogram: realogramCandidate,
      isCandidate: true,
      isCan: false,
    });
  const { isEnable: isCanEditFace } = useGetRealogramPermission({
    action: 'revise_face',
    realogram: realogramCandidate as RealogramCandidate,
    isCandidate: true,
    isCan: true,
  });
  const { isEnable: isCanDeleteFace } = useGetRealogramPermission({
    action: 'delete_face',
    realogram: realogramCandidate as RealogramCandidate,
    isCandidate: true,
    isCan: true,
  });
  const { isEnable: isCanDeleteBaypart } = useGetRealogramPermission({
    action: 'delete_bay_part',
    realogram: realogramCandidate as RealogramCandidate,
    isCandidate: true,
    isCan: true,
  });
  const { isNotEnable: isNotCanDelete } = useGetRealogramPermission({
    action: 'delete',
    realogram: realogramCandidate as RealogramCandidate,
    isCandidate: true,
    isCan: false,
  });
  const { handleClickDeleteFace } = useDeleteRealogramFace({
    realogramCandidateId,
    refetchRealogramCandidate,
    realogramDetailView,
    selectedItem,
  });
  useEffect(() => {
    setStatus(data?.realogram_candidate?.status);
  }, [data]);

  // 初回描画：ヘッダータブ
  useEffect(() => {
    if (initRerenderingHeaderStatus || isLoadingTenant) return;

    // 通常モード・比較モード
    if (selector.modeQueryParams) {
      setRealogramDetailMode(selector.modeQueryParams);
    }

    // view=layout
    if (selector.viewQueryParams === 'default') {
      setRealogramDetailView(selector.viewQueryParams);
    }
    // view=attribute
    else if (selector.viewQueryParams === 'productFlag') {
      setRealogramDetailView(selector.viewQueryParams);
      setProductTag(
        selector.attributeQueryParams
          ? selector.attributeQueryParams
          : 'new_products_first_week'
      );
    }
    // view=analytics
    else if (selector.viewQueryParams === 'profit' && isTenantSalesAnalytics) {
      const profitValue = selector.profitTab
        ? selector.profitTab
        : profitTabSales;
      setRealogramDetailView(selector.viewQueryParams);
      setProfitTab(profitValue);
      // Update profit value in comparison mode when back-browsing
      if (selector.modeQueryParams === 'comparison') {
        setComparisonProfitTabValue(profitValue);
      }
    }
    // view=evaluation
    else if (selector.viewQueryParams === 'rate' && canReadDemo) {
      setRealogramDetailView(selector.viewQueryParams);
      setRateValue(
        selector.evaluationQueryParams
          ? selector.evaluationQueryParams
          : '評価OK'
      );
    }
    setInitRerenderingHeaderStatus(true);
  }, [
    initRerenderingHeaderStatus,
    selector.attributeQueryParams,
    selector.evaluationQueryParams,
    selector.profitTab,
    selector.modeQueryParams,
    selector.viewQueryParams,
    isTenantSalesAnalytics,
    dispatch,
    isLoadingTenant,
    canReadDemo,
  ]);

  // 初回描画のみ：URLの更新
  useEffect(() => {
    if (
      initUpdateUrl ||
      !initRerenderingHeaderStatus ||
      isLoading ||
      isLoadingTenant
    )
      return;

    operate.updateUrlQueryParams({
      ...selector,
      isProduct: !!product,
    });
    setInitUpdateUrl(true);
  }, [
    initRerenderingHeaderStatus,
    initUpdateUrl,
    isLoading,
    isLoadingTenant,
    operate,
    product,
    selector,
  ]);

  useEffect(() => {
    /**
     * ブラウザバックなどで選択した商品がundefinedになっていたらドロワーを閉じる
     * productCandidatesの取得も不要
     */
    if (!selectedItem) {
      setIsDrawerOpen(false);
      setSelectedProductChoice(false);
      return;
    }
    //productCandidatesの一括取得
    const ids = Array.from(
      new Set(
        selectedItem?.item?.product_candidates
          ?.map((candidate) => candidate.product_id)
          .filter((v) => v !== undefined)
          .sort()
      )
    );
    setProductCandidateIds(ids.join(','));
  }, [selectedItem, setIsDrawerOpen, setSelectedProductChoice]);

  // Re-Call API getRealogramCandidate every 5s until the status changes to processing
  useEffect(() => {
    if (status !== 'processing' && status !== 'initialized') return;

    const intervalRefetchRealogramCandidate = setInterval(async () => {
      await refetchRealogramCandidate();
    }, timer);

    return () => {
      clearInterval(intervalRefetchRealogramCandidate);
    };
  }, [refetchRealogramCandidate, status]);

  const handleModalOpen = () => {
    setMemoModal(true);
  };

  const submitMemo = () => {
    // TODO submit handler
  };

  const handleDeleteRealogram = async () => {
    setDeleteDialogOpen(false);
    setIsErrorComponent(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteRealogram({
        realogramCandidateId,
      });
      navigate(
        isErrorComponent
          ? paths.actuals.foldersRecentlyScanned
          : paths.actuals.viewList
      );
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteScanResult,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteScanResult,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleOpenReferenceImagePreview = () => {
    setReferenceImagePreview(true);
  };

  const handleClickDeleteShelfBoard = async (shelfBoardId: string) => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      // need unwrap for using try and catch
      await deleteShelfBoard({
        realogramCandidateId,
        shelfBoardId,
      }).unwrap();
      await refetchRealogramCandidate();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteShelfBoard,
        })
      );
    } catch (e) {
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteShelfBoard,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleModalClose = () => {
    setMemoModal(false);
  };

  const handleClickBbox = (item: RealogramSelectedItem) => {
    if (isUnknownProductsReviseMode) return;
    const isSelected = isSelectedItem(realogramDetailView, item, selectedItem);
    if (isSelected) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
    updateQueryParameter('item', item.compartmentId);
    scrollToCenterOfList(
      listItemRefs,
      `${item.shelfBoardId}-${item.compartmentId}-${item.item.id}`
    );
  };

  const handleCorrectDrawerOpen = (item: RealogramSelectedItem) => {
    dispatch(updateSelectedRealogramItem(item));
    const isAddedProduct = item.item.product_candidates.length === 0;
    updateQueryParameter('item', item.compartmentId);
    performance.mark('start');
    if (!item.item.in_stock || isAddedProduct) {
      setSelectedCandidate(item.item.primary_candidate);
      setSelectedProductChoice(true);
    } else {
      handleProductCandidatesDrawerOpen();
    }
  };

  const handleDivisionModalOpen = (face: RealogramCandidateFace) => {
    setIsDivisionModalOpen(true);
    setFace(face);
  };

  // 商品属性の中の項目を更新
  const handleChangeProductTag = (productTag: ProductTag) => {
    setProductTag(productTag);
    updateAttributeQueryParams(productTag);
  };

  // 評価の中の属性を更新
  const handleChangeRateValue = (value: Rate) => {
    setRateValue(value);
    updateEvaluationQueryParams(value);
  };

  // 分析の中の属性を更新
  const handleChangeProfitTabValueValue = (profitTab: ProfitTab) => {
    setProfitTab(profitTab);
    updateAnalyticsQueryParams(profitTab);
    setIsAscending(false);
  };

  const handleClickFace: (item: RealogramSelectedItem) => void = (item) => {
    const isSelected = isSelectedItem(realogramDetailView, item, selectedItem);
    if (isSelected) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
    updateQueryParameter('item', item.compartmentId);
  };

  // 通常・比較モードの切り替え
  const handleChangeMode = (mode: ShelfDetailMode) => {
    if (!mode) return;
    const profitValue = mode === 'default' ? profitTab : profitTabSales;
    setRealogramDetailMode(mode);
    updateModeQueryParams(
      mode,
      realogramDetailView,
      productTag,
      profitValue,
      rateValue
    );
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateProductPosition(undefined));
  };

  // 表示内容の切り替え
  const handleChangeView = (view: ShelfDetailView) => {
    if (!view) return;
    const profitValue =
      realogramDetailMode === 'default' ? profitTab : profitTabSales;
    setRealogramDetailView(view);
    updateViewQueryParams(view, productTag, profitValue, rateValue);
  };

  const createdAt = format(
    realogramCandidate?.created_at,
    Format.datetimeWithWeekday
  );

  const handleClickBackButton = () => {
    resetComparisonItemModalState();

    navigate(
      lastVisitedRealogramListURL
        ? lastVisitedRealogramListURL
        : sessionLastVisitedRealogramListURL
    );
  };

  const handleChangeCandidate = async (
    mostHighScoreCandidate?: ProductCandidateType
  ) => {
    if (!selectedItem) return;
    const realogramCandidateId = params?.id ? parseInt(params.id) : 0;
    const args = getArgs(
      realogramCandidateId,
      selectedItem,
      false,
      !mostHighScoreCandidate ? selectedCandidate : mostHighScoreCandidate
    );
    dispatch(updateLoadingIndicatorState(true));
    try {
      const { realogram_candidate } = await updateFace(args).unwrap();
      const shelfBoards =
        realogram_candidate?.detail?.products_shelves.shelf_boards;
      if (shelfBoards?.length) {
        dispatch(
          updateSelectedRealogramItem(
            getNewSelectedItem(shelfBoards, selectedItem)
          )
        );
      }
      await refetchRealogramCandidate();
      setSelectedProductChoice(false);
      setIsDrawerOpen(false);
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.corrected,
        })
      );
      await refetchRealogramAnalytics();
    } catch (e) {
      console.log(e, 'error');
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate({
      pathname: paths.actuals.id(
        String(realogramCandidate?.link?.next_created_realogram_candidate_id)
      ),
      search: createSearchParams(searchParams).toString(),
    });
  };

  const onClickPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate({
      pathname: paths.actuals.id(
        String(realogramCandidate?.link?.prev_created_realogram_candidate_id)
      ),
      search: createSearchParams(searchParams).toString(),
    });
  };

  const handleClickChangePermission = () => {
    if (!realogramCandidate?.directory_id) return;
    dispatch(setDirectoryId(realogramCandidate?.directory_id));
    dispatch(setDirectoryType('realogram'));
    showLicenseModal({
      type: 'file',
    });
  };

  const handleSort = () => {
    setIsAscending(!isAscending);
  };

  const handleClickOutSideRealogramBbox = () => {
    if (selectedItem) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
    }
  };

  const handleClickReUpLoadScan = () => {
    navigate(paths.actuals.scan);
  };

  const handleProductCandidatesDrawerOpen = () => {
    performance.mark('start');
    setIsDrawerOpen(true);
  };

  const handleUnknownProductsReviseMode = () => {
    setIsUnknownProductsReviseMode(true);
    if (!selectedItem) return;
    dispatch(updateSelectedRealogramItem(undefined));
  };

  const handleAddFaceMode = (shelfBoardIndex: number) => {
    dispatch(updateSelectedRealogramItem(undefined));
    handleAddFace(shelfBoardIndex);
  };

  const handleSaveProductFace = async (
    crop: Crop,
    zoomScale: number,
    ratio: number
  ) => {
    await handleSaveNewFace(crop, zoomScale, ratio);
    await refetchRealogramCandidate();
  };

  if (
    Number.isNaN(realogramCandidateId) ||
    (!!realogramCandidateError &&
      'status' in realogramCandidateError &&
      realogramCandidateError.status === httpStatus.NOT_FOUND)
  ) {
    return <NotFound title="棚割実績（棚スキャナ）" />;
  }

  return (
    <>
      {isAddFaceMode ? (
        <AddFaceMode
          addFaceModeLabel={addFaceModeLabel}
          shelfBoards={shelfBoards}
          realogramImage={realogramImage}
          compartmentNumber={compartmentNumber}
          shotBboxes={shotBboxes}
          canNotShowImage={canNotShowImage}
          isLarger={isLarger}
          maxWidth={
            data?.realogram_candidate.detail?.products_shelves_image.width
          }
          maxHeight={
            data?.realogram_candidate.detail?.products_shelves_image.height
          }
          setCompartmentNumber={setCompartmentNumber}
          handleAddFaceMode={handleAddFaceMode}
          handleSaveNewFace={handleSaveProductFace}
        />
      ) : (
        <Box
          component="div"
          sx={{
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <RealogramDetailHeader
            realogramCandidate={realogramCandidate}
            handleClickBackButton={handleClickBackButton}
            handleClickMemoButton={handleModalOpen}
            handleClickDeleteButton={() => setDeleteDialogOpen(true)}
            handleClickCreatePlanButton={handleCreatePlanogram}
            handleClickMoreInfoButton={() => setMoreInfoDialog(true)}
            mode={realogramDetailMode}
            view={realogramDetailView}
            handleChangeMode={handleChangeMode}
            handleChangeView={handleChangeView}
            handleClickChangePermission={handleClickChangePermission}
            isDisabledAction={!isProcessed}
            isShowAnalyticsButton={isTenantSalesAnalytics}
            isNotCanDelete={isNotCanDelete}
            bayPlanName={bayPlanCode?.name}
          />
          {isComparedMode ? (
            <Comparison
              shelfBoards={
                realogramCandidate?.detail?.products_shelves.shelf_boards ?? []
              }
              filteredShelfboards={shelfBoards}
              primaryCandidates={primaryCandidates}
              view={realogramDetailView}
              mode={realogramDetailMode}
              realogramCandidateId={realogramCandidateId}
              createdAt={createdAt}
              storeBayName={realogramCandidate?.store_bay.name ?? ''}
              productTag={productTag}
              rate={rateValue}
              handleChangeProductTag={handleChangeProductTag}
              handleChangeRate={handleChangeRateValue}
              setComparisonProfitTabValue={setComparisonProfitTabValue}
              comparisonProfitTabValue={comparisonProfitTabValue}
              isImageLoading={imageIsLoading}
              handleChangeLoading={() => setImageIsLoading(true)}
              realogramCandidate={realogramCandidate}
              isBucketType={isBucketType}
              isTenantSalesAnalytics={isTenantSalesAnalytics}
              isGetRealogramCandidateLoading={isLoading}
              initRerenderingSelectedProduct={initRerenderingSelectedProduct}
              setInitRerenderingSelectedProduct={
                setInitRerenderingSelectedProduct
              }
              compareQueryParams={selector.compareQueryParams}
              report={displayAnalyticsData}
              analyticsDataTerm={analyticsDataTerm}
              disableChangeToPreviousWeek={disableChangeToPreviousWeek}
              disableChangeToNextWeek={disableChangeToNextWeek}
              handleNextWeekTerm={handleNextWeekTerm}
              handlePreviousWeekTerm={handlePreviousWeekTerm}
              realogramStatistics={realogramStatistics}
              isLoadingAnalyticsData={isLoadingAnalyticsData}
            />
          ) : (
            // 通常モード
            <RealogramDetail
              status={status}
              isProcessed={isProcessed}
              isBucketType={isBucketType}
              handleClickOutSideRealogramBbox={handleClickOutSideRealogramBbox}
              realogramCandidate={realogramCandidate}
              realogramDetailMode={realogramDetailMode}
              realogramDetailView={realogramDetailView}
              imageIsLoading={imageIsLoading}
              shelfBoards={shelfBoards}
              handleClickBbox={handleClickBbox}
              createdAt={createdAt}
              onClickNext={onClickNext}
              onClickPrev={onClickPrev}
              rateValue={rateValue}
              profitTab={profitTab}
              analyticsData={analyticsData}
              isUnknownProductsReviseMode={isUnknownProductsReviseMode}
              unknownProductsInfo={unknownProductsInfo}
              setImageIsLoading={setImageIsLoading}
              handleResetReviseMode={handleResetReviseMode}
              isEnableReviseButton={isEnableReviseButton}
              handleOnBulkRevise={handleOnBulkRevise}
              isLarger={isLarger}
              realogramImageElement={realogramImageElement}
              isCheckedRecommendedRevise={isCheckedRecommendedRevise}
              handleCheckboxChange={handleCheckboxChange}
              handleToggleRecommendedRevise={handleToggleRecommendedRevise}
              hasRecommendProducts={hasRecommendProducts}
              productTag={productTag}
              handleChangeProductTag={handleChangeProductTag}
              handleChangeRateValue={handleChangeRateValue}
              selectedItem={selectedItem}
              rateValues={rateValues}
              productTagValues={productTagValues}
              score={score}
              handleChangeProfitTabValueValue={handleChangeProfitTabValueValue}
              handleUnknownProductsReviseMode={handleUnknownProductsReviseMode}
              isCanNotEditProductAndCompartment={
                isCanNotEditProductAndCompartment
              }
              primaryCandidates={primaryCandidates}
              referenceImage={referenceImage}
              selectNext={selectNext}
              selectPrevious={selectPrevious}
              handleProductCandidatesDrawerOpen={
                handleProductCandidatesDrawerOpen
              }
              setSelectedProductChoice={setSelectedProductChoice}
              shouldButtonBeDisabled={shouldButtonBeDisabled}
              handleOpenReferenceImagePreview={handleOpenReferenceImagePreview}
              setSelectedProductZoomIn={setSelectedProductZoomIn}
              realogramCandidateId={realogramCandidateId}
              isTenantSalesAnalytics={isTenantSalesAnalytics}
              handleChangeCandidate={handleChangeCandidate}
              listItemRefs={listItemRefs}
              shelfBoardsToDisplay={shelfBoardsToDisplay}
              handleClickFace={handleClickFace}
              handleClickDeleteShelfBoard={handleClickDeleteShelfBoard}
              handleClickDeleteFace={handleClickDeleteFace}
              handleCorrectDrawerOpen={handleCorrectDrawerOpen}
              handleDivisionModalOpen={handleDivisionModalOpen}
              handleSort={handleSort}
              isAscending={isAscending}
              isCanEditFace={isCanEditFace}
              isCanDeleteFace={isCanDeleteFace}
              isCanDeleteBaypart={isCanDeleteBaypart}
              setDeleteDialogOpen={setDeleteDialogOpen}
              setIsErrorComponent={setIsErrorComponent}
              handleClickReUpLoadScan={handleClickReUpLoadScan}
              analyticsDataTerm={analyticsDataTerm}
              displayAnalyticsData={displayAnalyticsData}
              disableChangeToPreviousWeek={disableChangeToPreviousWeek}
              disableChangeToNextWeek={disableChangeToNextWeek}
              displayAnalyticsDataIndex={displayAnalyticsDataIndex}
              handleNextWeekTerm={handleNextWeekTerm}
              handlePreviousWeekTerm={handlePreviousWeekTerm}
              handleAddFaceMode={handleAddFaceMode}
              realogramStatistics={realogramStatistics}
              isLoadingAnalyticsData={isLoadingAnalyticsData}
              setSelectedCandidate={setSelectedCandidate}
              isRealogramLoading={isRealogramCandidateFetching}
            />
          )}
        </Box>
      )}
      <ProductCandidatesDrawer
        open={isDrawerOpen}
        products={products}
        referenceImage={referenceImage}
        selectedItem={selectedItem}
        bottomDrawerOpen={bottomDrawerOpen}
        primaryCandidate={primaryCandidate}
        productCandidates={productCandidates}
        selectedCandidate={selectedCandidate}
        productIdFrom={productIdFrom as number | 'unknown'}
        isUnknown={!!isUnknown}
        productCandidatesLength={productCandidatesLength}
        selectedUnknown={selectedUnknown}
        isLoading={isLoadingUpdateFace}
        isResetData={isResetData}
        selectedProductChoice={selectedProductChoice}
        handleOpenReferenceImagePreview={handleOpenReferenceImagePreview}
        handleClose={handleProductCandidatesDrawerClose}
        reset={reset}
        handleProductClick={handleProductClick}
        handleProductChoiceClick={handleProductChoiceClick}
        handleSpecialProductClick={handleSpecialProductClick}
        handleChangeCandidate={handleChangeCandidateInDrawer}
        handleCloseProductChoice={handleCloseProductChoice}
        setSelectedProductZoomIn={setSelectedProductZoomIn}
        isDisplayProductDetail={isDisplayProductDetail}
        setIsDisplayProductDetail={setIsDisplayProductDetail}
        setSelectedCandidate={setSelectedCandidate}
        isLarger={isLarger}
        realogramProductsCategories={realogramProductsCategories as string[]}
      />
      <ParcelDivisionModal
        open={isDivisionModalOpen}
        handleClose={() => setIsDivisionModalOpen(false)}
        face={face}
        realogramCandidate={realogramCandidate}
        isRealogramCandidateLoading={isRealogramCandidateFetching}
      />
      <MemoModal
        open={memoModal}
        handleModalClose={handleModalClose}
        submitMemo={submitMemo}
        memoText={data?.realogram_candidate?.custom_field?.memo_text}
      />
      <DeleteRealogramDialog
        open={deleteDialogOpen}
        handleClickCancelButton={() => setDeleteDialogOpen(false)}
        handleClickConfirmButton={handleDeleteRealogram}
      />
      <ImageModal
        open={referenceImagePreview}
        image={{
          src: referenceImage,
          width: window.innerWidth,
          height: window.innerHeight,
        }}
        product={selectedProductZoomIn}
        handleClose={() => {
          setReferenceImagePreview(false);
          setSelectedProductZoomIn(undefined);
        }}
      />
      <RealogramInfoDialog
        open={moreInfoDialog}
        onClose={() => setMoreInfoDialog(false)}
        realogramCandidate={realogramCandidate}
      />
      <NotPermittedModal
        open={isForbidden}
        isScan
        selectedDirectoryId={selectedDirectoryId}
        errorMessage="この棚割のアクセス権がありません"
        buttonMessage="棚割計画に戻る"
      />
    </>
  );
};
