import { Categories } from '@components/organisms/categories/categories';
import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { getRealogramSalesEnded } from '@reducers/scanner';
import { useAppDispatch } from '@store/index';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import type { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { CategoriesInfo } from './fragments/categoriesInfo';
import { ProductSelectTitle } from './fragments/productSelectTitle';
import { ProductsSearchFields } from './fragments/productsSearchFields';
import { SearchResults } from './fragments/searchResults';
import { useProductsSearch } from './hooks/useProductsSearch';

type Props = {
  categoryIndex: number;
  handleBack: () => void;
  productCategoryHistory: ProductCategory[];
  categoryType: 'categories' | 'list';
  handleClick: (category: ProductCategory) => void;
  fetchFilteredCategories: (text: string) => void;
  handleClickProductCategory: (category: ProductCategory) => void;
  drawerOpen?: boolean; // drawerで利用する時に必要
  handleSelectProductClick: (product: Product) => void; // actuals配下での利用
  clearProductState: () => void;
  filteredCategories: ProductCategory[];
  candidateItemId?: number;
  filteredCategoriesByProductDivisionCode?: ProductCategory[];
};

export const ProductsSelectOnly: FC<Props> = ({
  categoryIndex,
  handleBack,
  productCategoryHistory,
  categoryType,
  handleClick,
  filteredCategories,
  fetchFilteredCategories,
  handleClickProductCategory,
  drawerOpen,
  handleSelectProductClick,
  clearProductState,
  candidateItemId,
  filteredCategoriesByProductDivisionCode,
}) => {
  const dispatch = useAppDispatch();

  const {
    filterCondition,
    isSearchResults,
    selectedCategory,
    selectedTags,
    isSalesEnded,
    isUsingProducts,
    isSearch,
    control,

    isLoadingNextPage,
    isLoadingSearchNextPage,
    data,
    searchedData,
    isSearchLoading,
    isLoading,
    isRealogramSalesEnded,

    focus,
    reset,
    handleResetConditions,
    handleBackToList,

    handleChangeSelectedTag,
    handleChangeSalesEnded,
    handleChangeIsUsingProducts,
    handleChangeSelectedCategory,
    handleSearch,
    handleDeleteTip,
    handleChangeSwitch,
    fetchNextPage,
    fetchSearchNextPage,
    setSelectedCategory,
  } = useProductsSearch({
    fetchFilteredCategories,
    filteredCategoriesByProductDivisionCode,
    categoryType,
    productCategoryName: productCategoryHistory[categoryIndex]?.name,
  });

  const productsRef = useRef<HTMLDivElement>(null);
  const [isDisplayAllCategories, setIsDisplayAllCategories] = useState(false);
  const [isFilteredRoot, setIsFilteredRoot] = useState(true);
  const handleEndReached = async () => {
    if (isLoadingNextPage) return;
    await fetchNextPage();
  };

  const handleSearchEndReached = async () => {
    if (isLoadingSearchNextPage) return;
    await fetchSearchNextPage();
  };

  useEffect(() => {
    const searchedText = document.getElementById('searchedTextId');
    if (searchedText && isSearch) {
      searchedText.focus();
    }
  }, [isSearch]);

  useEffect(() => {
    if (!drawerOpen) reset();
  }, [drawerOpen, reset]);

  useEffect(() => {
    dispatch(getRealogramSalesEnded());
  }, [dispatch]);

  const handleChangeSalesEndedAndOffset = () => {
    handleChangeSwitch('isSalesEnded');
  };

  const handleFocusSearchField = () => {
    focus();
    clearProductState();
  };

  const categoryOptions = useMemo(() => {
    const categories = [
      {
        id: '0',
        label: '全カテゴリ',
      },
    ];
    // 什器に紐づいたカテゴリをセット
    if (!isDisplayAllCategories) {
      categories.push({
        label: 'この什器に紐づくカテゴリ',
        id: '1',
      });
      // 現在の階層のカテゴリをセット
    } else if (productCategoryHistory[categoryIndex].name !== 'root') {
      categories.push({
        label: productCategoryHistory[categoryIndex].name,
        id: productCategoryHistory[categoryIndex].product_division_code ?? '',
      });
      setSelectedCategory(productCategoryHistory[categoryIndex].name);
    }
    return categories;
  }, [
    productCategoryHistory,
    setSelectedCategory,
    categoryIndex,
    isDisplayAllCategories,
  ]);

  const productCategories = isDisplayAllCategories
    ? productCategoryHistory[categoryIndex]?.sub_categories
    : filteredCategoriesByProductDivisionCode;
  const baseTitle =
    categoryIndex > 0
      ? productCategoryHistory[categoryIndex]?.name
      : '全カテゴリ';
  const title = isDisplayAllCategories ? baseTitle : 'この什器に紐づくカテゴリ';
  const isDisplayBackBtn = !isSearch && categoryIndex > 0;

  return (
    <Box
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
      component="form"
      display="flex"
      flexDirection="column"
      flex={1}
      width="100%"
    >
      {isSearch ? (
        <>
          {isSearchResults ? (
            // エディタとスキャナで別コンポーネントにする
            <>
              <Box
                component="div"
                sx={{
                  py: 1,
                  px: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  component="div"
                  display="flex"
                  flexDirection="row"
                  gap="8px"
                >
                  <IconButton
                    edge="start"
                    sx={{
                      border: `1px solid ${theme.palette.dividerBlack.dark}`,
                      borderRadius: 1,
                      width: '32px',
                      height: '32px',
                      ml: 0,
                    }}
                    onClick={() => {
                      handleBackToList();
                      clearProductState();
                    }}
                  >
                    <Close
                      fontSize="small"
                      htmlColor={theme.palette.icons.primary}
                    />
                  </IconButton>
                  <Typography
                    onClick={() => {
                      handleBack();
                    }}
                    component="h2"
                    sx={{
                      color: theme.palette.textBlack.primary,
                      fontSize: '16px',
                      fontWeight: 700,
                      letterSpacing: '0.15px',
                      cursor: categoryIndex > 0 ? 'pointer' : 'default',
                      display: 'inline-flex',
                      alignItems: 'center',
                      lineHeight: '175%',
                    }}
                  >
                    検索結果
                  </Typography>
                </Box>
                <Controller
                  name="searchText"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      key="searchedTextCollapsed"
                      placeholder="商品名・JAN・商品CDで検索"
                      {...field}
                      onFocus={handleFocusSearchField}
                      size="small"
                      sx={{
                        width: '250px',
                        'input::placeholder': {
                          fontSize: '14px',
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: '40px',
                          height: '32px',
                          backgroundColor: '#F7F7F7',
                        },
                        startAdornment: (
                          <Search
                            sx={{
                              color: theme.palette.icons.primary,
                              mr: '4px',
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Divider sx={{ mx: -1 }} />
              <SearchResults
                filterCondition={filterCondition}
                products={searchedData?.products}
                reset={() => {
                  reset();
                }}
                handleClickProductCategory={handleClickProductCategory}
                isLoading={isSearchLoading}
                productsRef={productsRef}
                isDraggable={false}
                handleClickProduct={handleSelectProductClick}
                total={searchedData?.total}
                handleEndReached={handleSearchEndReached}
                selectedItemId={candidateItemId}
                handleChangeSalesEnded={handleChangeSalesEnded}
                isSalesEnded={isRealogramSalesEnded}
                filteredCategories={filteredCategories}
                handleDeleteTip={handleDeleteTip}
                handleChangeSwitch={handleChangeSwitch}
                handleChangeSalesEndedAndOffset={
                  handleChangeSalesEndedAndOffset
                }
              />
            </>
          ) : (
            <>
              <Box
                component="div"
                sx={{
                  px: 2,
                  py: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <IconButton
                  edge="start"
                  sx={{
                    border: `1px solid ${theme.palette.dividerBlack.dark}`,
                    borderRadius: 1,
                    width: '32px',
                    height: '32px',
                    ml: 0,
                  }}
                  onClick={handleBackToList}
                >
                  <Close
                    fontSize="small"
                    htmlColor={theme.palette.icons.primary}
                  />
                </IconButton>

                <Controller
                  name="searchText"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="searchedTextId"
                      key="searchedTextFullWidth"
                      {...field}
                      onFocus={handleFocusSearchField}
                      placeholder="商品名・JAN・商品CDで検索"
                      size="small"
                      sx={{
                        width: '100%',
                        'input::placeholder': {
                          fontSize: '14px',
                        },
                        '& .MuiInputBase-input': {
                          px: 1,
                          '&:-webkit-autofill': {
                            // eslint-disable-next-line @typescript-eslint/naming-convention -- webkit
                            WebkitBoxShadow: '0 0 0 100px #F7F7F7 inset',
                            height: 0,
                          },
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: '40px',
                          backgroundColor: '#F7F7F7',
                          height: '32px',
                        },
                        startAdornment: (
                          <Search
                            sx={{
                              color: theme.palette.icons.primary,
                              mr: '4px',
                            }}
                          />
                        ),
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              reset();
                            }}
                          >
                            <GridCloseIcon
                              sx={{
                                width: '18px',
                                height: '18px',
                                cursor: 'pointer',
                                color: theme.palette.icons.primary,
                              }}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
                <Button
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    px: '16px',
                    py: 0,
                    minWidth: '90px',
                    height: '40px',
                  }}
                  onClick={handleResetConditions}
                >
                  リセット
                </Button>
                <Button variant="contained" type="submit">
                  検索
                </Button>
              </Box>
              <Divider sx={{ mx: -1 }} />
              <ProductsSearchFields
                filterCondition={filterCondition}
                isSalesEnded={isSalesEnded}
                isUsingProducts={isUsingProducts}
                categoryOptions={categoryOptions}
                selectedCategory={selectedCategory}
                selectedTags={selectedTags}
                handleChangeSelectedCategory={handleChangeSelectedCategory}
                handleChangeSelectedTag={handleChangeSelectedTag}
                handleChangeSalesEnded={handleChangeSalesEnded}
                handleChangeIsUsingProducts={handleChangeIsUsingProducts}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Box
            component="div"
            sx={{
              py: 1,
              px: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ProductSelectTitle
              categoryIndex={categoryIndex}
              isDisplayBackBtn={isDisplayBackBtn}
              title={title}
              handleClick={() => {
                if (categoryIndex == 1 && isFilteredRoot) {
                  setIsDisplayAllCategories(false);
                }
                handleBack();
              }}
            />
            <TextField
              key="searchedTextCollapsed"
              placeholder="商品名・JAN・商品CDで検索"
              onFocus={handleFocusSearchField}
              size="small"
              sx={{
                width: '250px',
                'input::placeholder': {
                  fontSize: '14px',
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: '40px',
                  height: '32px',
                  backgroundColor: '#F7F7F7',
                },
                startAdornment: (
                  <Search
                    sx={{
                      color: theme.palette.icons.primary,
                      mr: '4px',
                    }}
                  />
                ),
              }}
            />
          </Box>
          <Divider sx={{ mx: -1 }} />
          <Box
            component="div"
            sx={{
              pl: 2,
              pr: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {!isDisplayAllCategories && (
              <CategoriesInfo
                displayedCount={
                  filteredCategoriesByProductDivisionCode?.length ?? 0
                }
                totalCount={productCategoryHistory[0].sub_categories.length}
                handleClick={() => {
                  setIsFilteredRoot(false);
                  setIsDisplayAllCategories(!isDisplayAllCategories);
                }}
              />
            )}
            <Categories
              categoryName={productCategoryHistory[categoryIndex]?.name}
              categoryType={categoryType}
              productCategories={productCategories}
              handleClick={(category: ProductCategory) => {
                setIsDisplayAllCategories(true);
                handleClick(category);
              }}
              productsRef={productsRef}
              isDraggable={false}
              isLoading={isLoading}
              handleClickProduct={handleSelectProductClick}
              products={data?.products}
              handleEndReached={handleEndReached}
              selectedItemId={candidateItemId}
              isRealogram
            />
          </Box>
        </>
      )}
    </Box>
  );
};
