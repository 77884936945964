import { SalesEndSwitch } from '@components/molecules/salesEndSwitch/salesEndSwitch';
import { SearchTip } from '@components/molecules/searchTip/searchTip';
import { ProductsGrid } from '@components/organisms/productsGrid/productsGrid';
import { Box, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { isPlans } from '@utils/planogram';
import { FC, MutableRefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { FilterCondition } from '../types';
import { StateLayout } from './stateLayout';

type Props = {
  products?: Product[];
  reset: () => void;
  filteredCategories?: ProductCategory[];
  handleClickProductCategory: (category: ProductCategory) => void;
  isLoading: boolean;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isDraggable?: boolean;
  handleSelectProductClick?: (productId: Product['id']) => void; // Note: actuals配下で利用
  isCompareMode?: boolean;
  handleEndReached: (index: number) => void;
  total?: number;
  handleClickProduct: (product: Product) => void;

  selectedItemId?: number;
  handleChangeSalesEnded: VoidFunction;
  isSalesEnded?: boolean;
  isSalesEndSwitchBorderTop?: boolean;
  filterCondition: FilterCondition;
  handleDeleteTip: (key: keyof FilterCondition, tag?: string) => void;
  handleChangeSwitch: (key: keyof FilterCondition) => void;
  handleChangeSalesEndedAndOffset: VoidFunction;
};

const layoutPadding = { pl: 2, pr: 1 };

export const SearchResults: FC<Props> = ({
  filterCondition,
  products,
  reset,
  filteredCategories,
  handleClickProductCategory,
  isLoading,
  productsRef,
  isDraggable = true,
  isCompareMode = false,
  handleEndReached,
  handleClickProduct,
  total,
  selectedItemId,
  handleChangeSalesEndedAndOffset,
  handleDeleteTip,
}) => {
  const { productsListScrollPos } = useAppSelector(selectPlanogramEditorState);
  useEffect(() => {
    if (productsListScrollPos && productsRef.current) {
      productsRef.current.scrollTo({ top: productsListScrollPos });
    }
  }, [products?.length, productsListScrollPos, productsRef]);

  const handleCategoryClick = (category: ProductCategory) => {
    reset();
    handleClickProductCategory(category);
  };
  const { pathname } = useLocation();

  const isEmpty =
    products?.length === 0 && filteredCategories?.length === 0 && !isLoading;

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        pb: `${
          isPlans(pathname)
            ? filteredCategories && selectedItemId
              ? '145px'
              : '60px'
            : selectedItemId
            ? '145px'
            : '80px'
        }`,
        ...(isDraggable ? {} : layoutPadding),
        overflow: 'hidden',
      }}
      ref={productsRef}
    >
      <Box
        component="div"
        borderBottom={`1px solid ${theme.palette.dividerBlack.medium}`}
        py="5px"
        px="16px"
        display="flex"
        gap="8px"
        alignItems="center"
        overflow="auto"
        whiteSpace="nowrap"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <SalesEndSwitch
          isSalesEnded={filterCondition.isSalesEnded}
          handleChangeSalesEnded={handleChangeSalesEndedAndOffset}
          isLoading={isLoading}
          orientation="horizontal"
        />
        {/* create after backend is ready */}
        {/* <Box
          component="div"
          display="flex"
          flexDirection="row"
          textAlign="center"
          alignItems="center"
        >
          <Typography variant="body2" color={theme.palette.textBlack.secondary}>
            店舗取扱のない商品
          </Typography>
          <Switch
            checked={filterCondition.isUsingProducts}
            onChange={() => handleChangeSwitch('isUsingProducts')}
            disabled={isLoading}
          />
        </Box> */}
        {filterCondition.tags.length > 0 &&
          filterCondition.tags.map((tag) => (
            <SearchTip
              key={tag}
              text={tag}
              handleDelete={() => !isLoading && handleDeleteTip('tags', tag)}
            />
          ))}
        {filterCondition.category && (
          <>
            {filterCondition?.category?.map((category) => (
              <SearchTip
                key={category}
                text={category}
                handleDelete={() =>
                  !isLoading && handleDeleteTip('category', category)
                }
              />
            ))}
          </>
        )}
      </Box>
      <StateLayout isEmpty={isEmpty} isLoading={isLoading}>
        <Box
          component="div"
          width="100%"
          ml={1}
          pt={1}
          color="#757575" // set color directly at this moment
          gap={2}
          display="flex"
        >
          <Typography>商品</Typography>
          <Typography>{total}件</Typography>
        </Box>
        <ProductsGrid
          products={products}
          handleClick={handleClickProduct}
          isLoading={isLoading}
          handleEndReached={handleEndReached}
          isDraggable={isDraggable}
          isCompareMode={isCompareMode}
          selectedItemId={selectedItemId}
          filteredCategories={filteredCategories}
          handleCategoryClick={handleCategoryClick}
          isSearchResult
        />
      </StateLayout>
    </Box>
  );
};
