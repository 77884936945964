/* eslint filename-consistency/match: off -- ファイル名変更に伴い、呼び出し元もLintによる修正をする必要があるため */

// Imports
// Custom made components import
import {
  realogramDirectorySearchQueryKey,
  useRealogramDirectoriesSearch,
} from '@api/index';
import { queryClient } from '@api/query-client';
import { realogramsApi } from '@api/services/realogramDirectories';
import { ShelvesViewToggle } from '@components/molecules';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { FilterButton } from '@components/molecules/filterButton/filterButton';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import { AppBar, DeleteRealogramDialog } from '@components/organisms';
import {
  GridModeSort,
  ListOrder,
} from '@components/organisms/gridModeSort/gridModeSort';
import { RealogramsConditions } from '@components/organisms/realogramsConditions/realogramsConditions';
import { RealogramsSidebar } from '@components/organisms/realogramsSidebar/realogramsSidebar';
import { ScanPermissionDialog } from '@components/organisms/scanPermissionDialog/scanPermissionDialog';
import { useRerenderingScanner } from '@hooks/rerenderingComponents';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Box, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  setLastVisitedRealogramListURL,
  setRealogramsPageNumber,
  setSelectedDirectoryId,
  setSelectedItemIndex,
} from '@reducers/scanner';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import { useLazyListStoresQuery } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SidebarValue,
  getListPageTitle,
  getSortOptions,
  pageTitle,
  paths,
  removeFirstLastSpaces,
  rowsPerPage,
  toastMessages,
} from '@utils/const';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import {
  DirectoryBreadcrumbs,
  DirectoryType,
  ParentDirectory,
} from 'types/common';
import {
  RealogramDirectory,
  RealogramListOrder,
  RealogramOrder,
} from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { SearchText } from '../planograms/fragments/searchText';
import { realogramDirectoriesQueryKey } from '../storeBays';
import { RealogramsDirectoryGrid } from './fragments/ralogramsDirectoryGrid/realogramsDirectoryGrid';
import { RealogramsDirectoryTable } from './fragments/realogramsDirectoryTable/realogramsDirectoryTable';

const queryList = ['owner_id', 'name'];

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (sidebarValue === 'latest') {
    return '最近のスキャン';
  } else if (isRoot) {
    return 'スキャン結果';
  } else {
    return directoryParentName;
  }
};

export const Realograms: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setSessionLastVisitedRealogramListURL] = useSessionStorage<string>(
    'sessionLastVisitedRealogramListURL'
  );
  const { selectedDirectoryId } = useAppSelector((state) => state.Scanner);
  const { isViewFolder } = useAppSelector((state) => state.SharePermission);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);
  const [currentRealogramId, setCurrentRealogramId] = useState<number>();

  const { pathname, search } = location;
  const currentUrl = pathname + search;

  const {
    viewMode,
    condition,
    isShowFilters,

    setViewMode,
    setCondition,
    setIsShowFilters,

    conditionData: { me, userData, users },
    sidebarData: {
      sidebarValue,
      isFilteredByFavorite,
      isFilteredByLatest,
      isFilteredByViewed,
      defaultFirstOrderBySideBar,
    },
  } = useRerenderingScanner();

  const { hasQueryParameters } = useQueryParameterPresence();
  const hasQueries = hasQueryParameters(queryList);
  const { name } = condition ?? {};
  const params = useParams();
  const directoryId = params.id;
  const [fetchStoreData] = useLazyListStoresQuery();

  const isSkipListRealogramDirectoriesQuery =
    isFilteredByFavorite ||
    isFilteredByLatest ||
    isFilteredByViewed ||
    !!condition?.name;

  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
  } = useQuery({
    enabled: !isSkipListRealogramDirectoriesQuery,
    queryKey: [realogramDirectoriesQueryKey, directoryId, condition.firstOrder],
    queryFn: () =>
      realogramsApi.getRealogramDirectories({
        id: directoryId,
        status: condition?.status ? condition.status : undefined,
        first_order: condition?.firstOrder as RealogramOrder,
      }),
  });

  const isSkipSearchRealogramDirectoriesQuery =
    (!isSkipListRealogramDirectoriesQuery && !condition?.ownerId) ||
    // Prevent favoriteOwnerId is undefined when initializing starred page without userData
    (isFilteredByFavorite && !userData?.user.id);

  const {
    data: searchedData,
    fetchNextPage,
    isFetching: isSearchFetching,
    isFetchingNextPage,
    isLoading: isSearchLoading,
    hasNextPage,
  } = useRealogramDirectoriesSearch({
    enabled: !isSkipSearchRealogramDirectoriesQuery,
    name: condition?.name,
    first_order: condition.firstOrder as RealogramOrder,
    limit: rowsPerPage,
    owner_id: condition?.ownerId ? condition.ownerId : undefined,
    status: condition?.status ? condition.status : undefined,
    favorite_owner_id:
      isFilteredByFavorite && userData?.user.id
        ? [userData.user.id]
        : undefined,
    directory_type: isFilteredByLatest ? 'file' : undefined,
    directory_ancestor_id: directoryId ? directoryId : undefined,
  });

  const { mutateAsync: deleteRealogram } = useMutation({
    mutationFn: (id: number) => realogramsApi.deleteRealogram(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
    },
  });

  const { mutateAsync: addRealogramToFavorite } = useMutation({
    mutationFn: realogramsApi.addRealogramToFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });
  const { mutateAsync: deleteRealogramFromFavorite } = useMutation({
    mutationFn: realogramsApi.deleteRealogramFromFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });

  // these 2 fields only used to determine hightlight filter button or not
  const planogramConiditionFilters = {
    statusId: condition?.status,
    ownerId: condition?.ownerId,
  };

  const hasCondition = Object.values(planogramConiditionFilters).some((el) => {
    if (Array.isArray(el)) {
      return !!el.length;
    }
    return !!el;
  });

  const isLoadingData = isSearchLoading || isDirectoryLoading;
  const isFetchingData = isSearchFetching || isDirectoryFetching;
  const shouldChangeColor = hasCondition && !isShowFilters;
  const filterButtonTextColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.textBlack.primary;
  const filterButtonIconColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.icons.primary;
  const isRoot = directory?.parent.type === 'root';
  const isShowBreadcrumbList = !!directory && !!directoryId;

  const realogramDirectories = isSkipSearchRealogramDirectoriesQuery
    ? directory?.realogram_directories
    : searchedData;

  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });

  useEffect(() => {
    if (!isFirstRender && name) {
      setIsFirstRender(true);
    }
  }, [isFirstRender, name]);

  useEffect(() => {
    if (
      !selectedDirectoryId &&
      !isViewFolder &&
      directory &&
      directory.parent.type !== 'root'
    ) {
      dispatch(setSelectedDirectoryId(directory.parent.id));
    }
  }, [directory, selectedDirectoryId, dispatch, isViewFolder]);

  const navigateToScanner = async () => {
    dispatch(setRealogramsPageNumber(1));
    dispatch(updateLoadingIndicatorState(true));
    const stores = await fetchStoreData({
      filterByRole: 'editor',
    });
    if (stores.data?.stores?.length) {
      navigate(paths.actuals.scan);
    } else {
      setIsPermissionDialogOpen(true);
    }
    dispatch(updateLoadingIndicatorState(false));
  };

  const handleClickDirectory = (item: RealogramDirectory) => {
    const directoryId = item.id;
    dispatch(setSelectedDirectoryId(directoryId));
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
      },
      {
        directoryId,
      }
    );
  };

  const handleClickFile = (index: number) => {
    dispatch(setSelectedItemIndex(index));
    dispatch(setLastVisitedRealogramListURL(currentUrl));
    setSessionLastVisitedRealogramListURL(currentUrl);
  };

  const handleRowClick = (index: number, item: RealogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);
    } else if (item.type === 'file' && item.realogram_candidate_id) {
      handleClickFile(index);
    }
  };
  const handleOpenDeleteDialog = (id: number) => {
    setDeleteDialogOpen(true);
    setCurrentRealogramId(id);
  };

  const handleDeleteRealogram = async () => {
    setDeleteDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteRealogram(currentRealogramId ?? 0);
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteScanResult,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteScanResult,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  usePageTitle('スキャン結果一覧');

  const handleClickMoveParentDirectory = (parentId?: string) => {
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;

    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
  };

  const afterHandleChangeBreadCrumb = (data: {
    isBackToRootDirectory: boolean;
    hasCondition: boolean;
    directoryId: string;
  }) => {
    const { directoryId, isBackToRootDirectory } = data;
    dispatch(setSelectedDirectoryId(directoryId));
    const newCondition = {
      ...condition,
      name: undefined,
    };
    if (isBackToRootDirectory) {
      setCondition(newCondition, {
        isToRootDirectory: true,
      });
    } else {
      setCondition(newCondition, {
        directoryId,
      });
    }
  };

  const onSubmit = (text: string) => {
    const name = removeFirstLastSpaces(text);
    if (name === '') return;
    // always search in the tab all
    const firstOrder = defaultFirstOrderBySideBar['all'];
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        firstOrder,
        name,
      },
      { isToRootDirectory: true }
    );
  };

  const clearTextSearch = () => {
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
      },
      {
        isToRootDirectory: true,
      }
    );
  };

  const handleChangeSelectedOwnerId = (ownerId?: number[]) => {
    const isClear =
      condition?.ownerId?.at(0) === ownerId?.at(0) || !ownerId?.length;
    if (isClear) {
      // Because the filter has only ownerId -> Clear directory_ancestor_id to refetch ListRealogramDirectoriesQuery again
      setCondition({
        ...condition,
        ownerId: undefined,
      });
    } else {
      setCondition({
        ...condition,
        ownerId,
      });
    }
  };

  const clearFilters = () => {
    setCondition({
      ...condition,
      ownerId: undefined,
      status: undefined,
    });
  };

  const handleShowFilters = () => {
    setIsShowFilters(!isShowFilters);
  };

  const handleChangeOrder = (firstOrder?: ListOrder) => {
    const newFirstOrder =
      isFilteredByViewed && !firstOrder
        ? defaultFirstOrderBySideBar['viewed']
        : firstOrder;
    setCondition({
      ...condition,
      firstOrder: newFirstOrder as RealogramListOrder,
    });
  };

  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteRealogramFromFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await addRealogramToFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSidebar = (value: SidebarValue) => {
    if (sidebarValue === value) return;
    const firstOrder = defaultFirstOrderBySideBar[value];
    dispatch(setSelectedDirectoryId(''));
    setIsShowFilters(false);
    setCondition(
      {
        ...condition,
        name: undefined,
        ownerId: undefined,
        status: undefined,
        firstOrder: firstOrder,
      },
      {
        sideBarValue: value,
      }
    );
  };

  const handleEndReached = (isAtBottom: boolean) => {
    if (!isAtBottom || !hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  };

  const { showLicenseModal } = useSharePermissionModal();

  const handleSharePermission = (item: RealogramSharePermissionProps) => {
    if (!item.directoryId) return;
    dispatch(setDirectoryId(`${item?.directoryId}`));
    dispatch(setDirectoryType('realogram'));
    showLicenseModal({
      type: item.type,
    });
  };

  const isApiLoading =
    isDirectoryLoading ||
    isDirectoryFetching ||
    isSearchLoading ||
    isSearchFetching;
  const isDisplayLoadingSkeleton = isApiLoading && !isFetchingNextPage;
  const emptyTableMessage = hasQueries
    ? '条件を変更してください。'
    : '左上の「新規スキャン」ボタンから追加してください。';
  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, []);

  return (
    <>
      <AppBar title="棚割実績（棚スキャナ）" />
      <Box component="div" display="flex">
        <RealogramsSidebar
          navigateToScanner={navigateToScanner}
          sidebarValue={sidebarValue}
          onClickTabAll={() => handleClickSidebar('all')}
          onClickTabFavorite={() => handleClickSidebar('favorite')}
          onClickTabLatest={() => handleClickSidebar('latest')}
          onClickTabViewed={() => handleClickSidebar('viewed')}
        />
        <Box component="div" mt={2} px={3} flex={1}>
          {/*56px = 40 AppBar + 16px margin*/}
          <Box
            component="div"
            width="100%"
            height="calc(100vh - 57px)"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Box component="div" display="flex" justifyContent="space-between">
              <SearchText
                key={condition.name}
                searchText={condition.name}
                handleSearch={onSubmit}
                clearSearch={clearTextSearch}
              />
              <ShelvesViewToggle
                mode={viewMode}
                onChange={(mode) => {
                  setViewMode(mode);
                }}
              />
            </Box>

            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={-1}
            >
              {isShowBreadcrumbList ? (
                <Box component="div">
                  {!isRoot && (
                    <Box component="div" marginLeft={5}>
                      <BreadcrumbList
                        breadcrumbs={
                          directory.breadcrumb as unknown as DirectoryBreadcrumbs
                        }
                        parentDirectory={
                          directory.parent as unknown as ParentDirectory
                        }
                        screenType="Main"
                        fontSize="12px"
                        handleClickBreadcrumbs={handleClickBreadcrumbs}
                        directoryRootName="スキャン結果"
                      />
                    </Box>
                  )}
                  <Box component="div" display="flex" alignItems="center">
                    {!isRoot && (
                      <GoParentDirectoryButton
                        width={32}
                        height={32}
                        handleClick={() => {
                          handleClickMoveParentDirectory(
                            directory.parent.parent_id
                          );
                        }}
                      />
                    )}
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                      mr={!isRoot ? '6px' : ''}
                    >
                      {getDirectoryPageTitle(
                        sidebarValue,
                        isRoot,
                        directory.parent.name
                      )}
                    </Typography>
                    {!isRoot && (
                      <ActionVisible
                        isCanUpdate={isCanUpdate}
                        isCanRead={isCanRead}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <Typography fontWeight="bold" fontSize="20px">
                  {getListPageTitle({
                    defaultTitle: pageTitle.realogram,
                    sidebarValue,
                    isSearched: !!condition?.name,
                  })}
                </Typography>
              )}
              <Box component="div" display="flex" gap={1}>
                <FilterButton
                  onClick={handleShowFilters}
                  textColor={filterButtonTextColor}
                  iconColor={filterButtonIconColor}
                />
              </Box>
            </Box>

            {isShowFilters && (
              <RealogramsConditions
                me={me}
                users={users?.filter((user) => user.id !== me?.id)}
                selectedOwnerId={condition?.ownerId?.toString() ?? ''}
                handleChangeSelectedOwnerId={handleChangeSelectedOwnerId}
                selectedStatus={condition?.status?.toString() ?? ''}
                clearConditions={clearFilters}
              />
            )}
            {viewMode === 'grid' && (
              <GridModeSort
                sortOptions={getSortOptions(
                  'realogram',
                  sidebarValue === 'viewed'
                )}
                isLoadingData={isFetchingData || isLoadingData}
                firstOrder={condition?.firstOrder}
                handleChangeOrder={handleChangeOrder}
              />
            )}

            {!(
              directory?.realogram_directories.length || searchedData?.length
            ) &&
            !isLoadingData &&
            !isFetchingData &&
            viewMode === 'grid' ? (
              <Box component="div" height="calc(100% - 90px)">
                <EmptyResult
                  title="スキャン結果がありません"
                  message={emptyTableMessage}
                  isErrorIcon
                />
              </Box>
            ) : (
              <Box component="div" mb={2} height="100%">
                {viewMode === 'table' ? (
                  <RealogramsDirectoryTable
                    isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
                    isEmpty={!realogramDirectories?.length && !isLoadingData}
                    isRefetching={isFetchingData}
                    realogramDirectories={
                      realogramDirectories as RealogramDirectory[]
                    }
                    isFilteredByViewed={isFilteredByViewed}
                    firstOrder={condition.firstOrder}
                    viewMode={viewMode}
                    handleRowClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleChangeOrder={handleChangeOrder}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                    emptyTableMessage={emptyTableMessage}
                    emptyTableTitle="スキャン結果がありません"
                  />
                ) : (
                  <RealogramsDirectoryGrid
                    isLoading={
                      (isLoadingData || isFetchingData) && !isFetchingNextPage
                    }
                    isRefetching={isFetchingNextPage}
                    realogramDirectories={
                      realogramDirectories as RealogramDirectory[]
                    }
                    viewMode={viewMode}
                    isFilteredByViewed={isFilteredByViewed}
                    handleCardClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <DeleteRealogramDialog
        open={deleteDialogOpen}
        handleClickCancelButton={() => setDeleteDialogOpen(false)}
        handleClickConfirmButton={handleDeleteRealogram}
      />
      <ScanPermissionDialog
        open={isPermissionDialogOpen}
        handleCancel={() => setIsPermissionDialogOpen(false)}
      />
    </>
  );
};
