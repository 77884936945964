import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateProductsListScrollPos,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { selectScannerState } from '@reducers/scanner/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { removeFirstLastSpaces } from '@utils/const';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';
import { FilterCondition } from '../types';
import { useProductsApi } from '@api/hooks/useProductsApi';
import { SelectChangeEvent } from '@mui/material';
import { ProductCategory } from 'types/productCategories';

type FetchFilteredCategories = (text: string) => void;

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

/**
 * - orgamisms/products
 * @param fetchFilteredCategories
 * @returns
 */

type Props = {
  fetchFilteredCategories: FetchFilteredCategories;
  categoryType: string;
  productCategoryName: string;
  filteredCategoriesByProductDivisionCode?: ProductCategory[];
};

export const useProductsSearch = ({
  fetchFilteredCategories,
  categoryType,
  productCategoryName,
  filteredCategoriesByProductDivisionCode,
}: Props) => {
  const { tags } = useAppSelector(selectPlanogramEditorState);
  const { isRealogramSalesEnded } = useAppSelector(selectScannerState);
  const dispatch = useAppDispatch();
  // 検索条件画面
  const [isSearch, setIsSearch] = useState(false);
  // 検索結果画面
  const [isSearchResults, setIsSearchResults] = useState(false);
  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });
  {
    /* add isUsingProducts after backend API is ready */
  }
  // 検索ボタンを押した際にこちらにセットする
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({
    category: [],
    tags: [] as string[],
    isSalesEnded: true,
    isUsingProducts: true,
    name: '',
  });
  const [selectedCategory, setSelectedCategory] = useState('全カテゴリ');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isSalesEnded, setIsSalesEnded] = useState(true);
  const [isUsingProducts, setIsUsingProducts] = useState(true);

  const handleChangeSelectedCategory = (e: SelectChangeEvent) => {
    if (!e.target.value) return;
    setSelectedCategory(e.target.value);
  };
  const handleChangeSelectedTag = (tags: string[]) => {
    setSelectedTags(tags);
  };
  const handleChangeSalesEnded = () => {
    setIsSalesEnded(!isSalesEnded);
  };
  const handleChangeIsUsingProducts = () => {
    setIsUsingProducts(!isUsingProducts);
  };
  const { searchWord } = useAppSelector(selectPlanogramEditorState);

  const { selectedProductCompartment, isShowProductDetail } = useAppSelector(
    selectPlanogramEditorState
  );

  const focus = () => {
    setIsSearch(true);
    setIsSearchResults(false);
  };

  const reset = useCallback(() => {
    setValue('searchText', '');
    dispatch(updateProductsListScrollPos(0));
    dispatch(updateSelectedProductCompartment(undefined));
  }, [dispatch, setValue]);
  const handleResetConditions = () => {
    reset();
    setSelectedCategory('全カテゴリ');
    handleChangeSelectedTag([]);
    setIsSalesEnded(true);
  };

  const handleBackToList = () => {
    handleResetConditions();
    setIsSearch(false);

    //reset Filter Conditions to Default
    setFilterCondition({
      category: [],
      tags: [] as string[],
      isSalesEnded: true,
      isUsingProducts: true,
      name: '',
    });
  };

  const handleSearch = () => {
    if (selectedCategory === 'この什器に紐づくカテゴリ') {
      const filterCategory = filteredCategoriesByProductDivisionCode?.map(
        (category) => category.name
      );
      setFilterCondition({
        category: filterCategory,
        tags: selectedTags,
        isSalesEnded: isSalesEnded,
        isUsingProducts: isUsingProducts,
        name: getValues().searchText,
      });
    } else {
      const category =
        selectedCategory === '全カテゴリ' ? [] : [selectedCategory];
      setFilterCondition({
        category,
        tags: selectedTags,
        isSalesEnded: isSalesEnded,
        isUsingProducts: isUsingProducts,
        name: getValues().searchText,
      });
    }
    setIsSearchResults(true);
  };
  const handleChangeSwitch = (key: keyof FilterCondition) => {
    if (key === 'isSalesEnded') {
      setFilterCondition({
        ...filterCondition,
        isSalesEnded: !filterCondition.isSalesEnded,
      });
      setIsSalesEnded(!filterCondition.isSalesEnded);
    } else if (key === 'isUsingProducts') {
      setFilterCondition({
        ...filterCondition,
        isUsingProducts: !filterCondition.isUsingProducts,
      });
      setIsUsingProducts(!filterCondition.isUsingProducts);
    }
  };

  const handleDeleteTip = (key: keyof FilterCondition, deleteTag?: string) => {
    if (key === 'tags') {
      const newTags = filterCondition.tags.filter((tag) => tag !== deleteTag);
      setFilterCondition({
        ...filterCondition,
        tags: newTags,
      });
      setSelectedTags(newTags);
    } else if (key === 'category') {
      const newCategories = Array.isArray(filterCondition.category)
        ? filterCondition.category?.filter((category) => category !== deleteTag)
        : [];
      setFilterCondition({
        ...filterCondition,
        category: newCategories,
      });
      setSelectedCategory('全カテゴリ');
    }
  };

  const {
    data,
    searchedData,
    isLoadingNextPage,
    isLoadingSearchNextPage,
    isSearchLoading,
    isLoading,
    fetchNextPage,

    fetchSearchNextPage,
  } = useProductsApi({
    searchWord,
    categoryType,
    isRealogramSalesEnded,
    productCategoryName,
    isSearchResults,
    filterCondition,
    tags,
  });

  // 検索画面に戻った時に、直前の検索結果を表示する
  useEffect(() => {
    if (searchWord === '') {
      return;
    }
    const text = removeFirstLastSpaces(searchWord);
    setIsSearch(true);
    setValue('searchText', text);
    void fetchFilteredCategories(text);
  }, [searchWord, setValue, fetchFilteredCategories]);

  return {
    filterCondition,
    isSearchResults,
    selectedCategory,
    selectedTags,
    isSalesEnded,
    isUsingProducts,
    isSearch,
    control,
    searchWord,
    selectedProductCompartment,
    isShowProductDetail,
    isLoadingNextPage,
    isLoadingSearchNextPage,
    data,
    searchedData,
    isSearchLoading,
    isLoading,
    isRealogramSalesEnded,
    handleSubmit,
    setValue,
    focus,
    reset,
    handleResetConditions,
    handleBackToList,
    setSelectedCategory,
    setSelectedTags,
    handleChangeSelectedTag,
    handleChangeSalesEnded,
    handleChangeIsUsingProducts,
    handleChangeSelectedCategory,
    handleSearch,
    handleDeleteTip,
    handleChangeSwitch,
    fetchNextPage,
    fetchSearchNextPage,
  };
};
