import { TableRow } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { rowHeight } from '@utils/const';
import { ElementType } from 'react';
import { theme } from 'theme';
import { TableRowProps } from '../dataTable';

type Props<T> = TableRowProps<T> & {
  isNotCanRead?: boolean;
  handleRowClick: VoidFunction;
  component?: ElementType;
  href?: string;
};

export const DefaultVirtuosoTableRow = <T,>({
  isNotCanRead = false,
  handleRowClick,
  component = TableRow,
  href,
  ...props
}: Props<T>) => {
  return (
    <TableRow
      component={component}
      href={href}
      sx={{
        textDecoration: 'none',
        ':hover': {
          backgroundColor: CommonColors.lightGray,
        },
        cursor: isNotCanRead ? 'default' : 'pointer',
        '.MuiTableCell-root': {
          color: isNotCanRead
            ? theme.palette.textBlack.disabled
            : theme.palette.textBlack.table,
        },
        height: rowHeight,
      }}
      onClick={() => {
        if (isNotCanRead) return;
        handleRowClick();
      }}
      {...props}
    />
  );
};
