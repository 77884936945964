import { TableRowProps } from '@components/organisms';
import { DefaultVirtuosoTableRow } from '@components/organisms/dataTable/fragments/defaultVirtuosoTableRow';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { ElementType } from 'react';
import { RealogramDirectory } from 'types/realogram';

type Props<T> = TableRowProps<T> & {
  handleRowClick: VoidFunction;
  component?: ElementType;
  href?: string;
};

export const TableRowWrapper = <T,>({ handleRowClick, ...props }: Props<T>) => {
  const { isNotEnable: isNotCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: props.item as RealogramDirectory,
    isCandidate: false,
    isCan: false,
  });

  return (
    <DefaultVirtuosoTableRow
      isNotCanRead={isNotCanRead}
      handleRowClick={handleRowClick}
      {...props}
    />
  );
};
