import { EditPlanogramModalProps } from '@components/organisms';
import { ModeToggle } from '@components/organisms/viewModeToggle/fragments';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useModal } from '@hooks/useModal';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Edit } from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  updatePlanogramSearch,
  updateRealogramSearch,
} from '@reducers/comparisonItemModal';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import {
  forget as forgetPlanogramEditorState,
  rotateGondola,
  rotateGondolaInCompare,
  setIsTooltipOpen,
  setOpenOrientationMenu,
  setSelectedBucketId,
  setSelectedBucketIdClone,
  updateDetailMode,
  updateIsShowProductDetail,
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import {
  useGetStoreQuery,
  useOrganizationStatusesQuery,
  usePutPlanogramMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { isPlanogramBucketPlan } from '@utils/planogram';
import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShelfDetailMode } from 'types/common';
import { Planogram } from 'types/planogram';
import { number } from 'yup';
import { Header } from '../header/header';
import { GoBackDialog } from './fragments/goBackDialog';
import { PlanogramErrorDialog } from './fragments/planogramErrorDialog';
import { StyledButton } from './fragments/styledButton';
import { theme } from 'theme';
import { toastMessages, paths } from '@utils/const';
import { useBrowserDialogBeforeUnload } from '@hooks/useBrowserDialogBeforeUnload';

type Props = {
  name: string;
  bayPlanCodeId?: number;
  organizationStatusId?: number;
  planogram?: Planogram;
  planogramIsLoading: boolean;
  planogramIsFetching: boolean;
  handleChangeName: (value: string) => void;
  handleChangeBayPlanCodeId: (value?: number) => void;
  handleChangeOrganizationStatusId: (value?: number) => void;
  plan: Planogram['plan'];
  isDirty: boolean;
  mark: () => undefined;
  setZoomScale?: (zoomScale: number) => void;
};
export const PlanogramHeader: FC<Props> = ({
  name,
  bayPlanCodeId,
  organizationStatusId,
  planogram,
  planogramIsLoading,
  planogramIsFetching,
  handleChangeName,
  handleChangeBayPlanCodeId,
  handleChangeOrganizationStatusId,
  plan,
  isDirty,
  mark,
  setZoomScale,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [goBackDialogOpen, setGoBackDialogOpen] = useState(false);
  const { showModal: showEditPlanogramModal } =
    useModal<EditPlanogramModalProps>(
      'editPlanogram',
      'ゴンドラ名・ゴンドラ番号変更'
    );
  const { removeQueryParameter, updateQueryParameter } = useUrlQueryParams();
  const {
    overflows,
    detailMode: mode,
    flatOverflows,
    bucketsExceed,
    isSwappingBayPartMode,
  } = useAppSelector(selectPlanogramEditorState);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { bayPlanCode } = useBayPlanCodes(bayPlanCodeId);
  const { data } = useOrganizationStatusesQuery();
  const { data: storeBayPlan } = useGetStoreQuery(
    {
      storeId: planogram?.store_id ?? 0,
    },
    { skip: !planogram?.store_id }
  );

  const isPlanogramError = overflows.some((overflow) => overflow);
  const isFlatPlanogramError = flatOverflows.some((el) => el.overflow);
  const isBucketExceedSize = bucketsExceed.some((exceed) => exceed);
  const [dialogOpen, setDialogOpen] = useState(false); //商品が棚からはみ出てた状態で保存や作成しようとするとdialogがでてくる
  const [putPlanogram, { isLoading: isEditing }] = usePutPlanogramMutation();
  const { id } = useParams();
  const isDirtyHeader = useMemo(
    () =>
      !(
        name === planogram?.name &&
        organizationStatusId === planogram?.organization_status_id &&
        bayPlanCodeId == planogram?.bay_plan_code_id
      ),
    [name, organizationStatusId, bayPlanCodeId, planogram]
  );

  const isDisabledSaveButton = useMemo(() => {
    //TODO: 保存後のplanogramのrefetch間ボタンが活性化する
    if (isEditing || planogramIsLoading || planogramIsFetching) return true;
    if (!(isDirty || isDirtyHeader)) return true; //plan changes and header changes
    return false;
  }, [
    isEditing,
    planogramIsLoading,
    planogramIsFetching,
    isDirty,
    isDirtyHeader,
  ]);
  const bayPlanCodeGap = 1.5;

  const handleEditPlanogramName = () => {
    showEditPlanogramModal({
      name: name,
      bayPlanCodeId,
      onSubmit: ({ name: value, bayPlanCodeId }) => {
        handleChangeName(value);
        handleChangeBayPlanCodeId(bayPlanCodeId);
        dispatch(closeModal());
      },
    });
  };

  const handleSave = async () => {
    if (isPlanogramError || isFlatPlanogramError || isBucketExceedSize) {
      setDialogOpen(true);
      return;
    }
    await handleSavePlanogram();
  };

  const handleSavePlanogram = async () => {
    if (!planogram) return;
    setDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      await putPlanogram({
        id: planogram.id,
        body: {
          ...planogram,
          plan,
          name,
          bay_plan_code_id: bayPlanCodeId,
          organization_status_id: organizationStatusId,
        },
      }).unwrap();
      mark();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.savePlanogram,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.savePlanogram,
        })
      );
    } finally {
      dispatch(rotateGondola(0));
      dispatch(rotateGondolaInCompare(0));
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleDoneClick = () => {
    resetSearchInputInComparisonModal();
    if (!isDisabledSaveButton) {
      setGoBackDialogOpen(true);
      return;
    }
    if (id) {
      dispatch(forgetPlanogramEditorState());
      navigate(paths.plans.planogramId(Number(id)));
    }
  };

  const resetSearchInputInComparisonModal = () => {
    dispatch(updatePlanogramSearch(undefined));
    dispatch(updateRealogramSearch(undefined));
  };

  const handleChangeMode = (mode: ShelfDetailMode) => {
    if (mode) {
      if (isPlanogramBucketPlan(planogram?.plan) && setZoomScale)
        setZoomScale(1);
      dispatch(setIsTooltipOpen(false));
      dispatch(setSelectedBucketId(undefined));
      dispatch(setSelectedBucketIdClone(undefined));
      dispatch(updateDetailMode(mode));
      resetState();
      if (mode === 'comparison') {
        const originRealogramCandidateId =
          planogram?.link?.origin_realogram_candidate_id;
        const originPlanogramId = planogram?.link?.origin_planogram_id;
        const queryString =
          currentSelectedItemId && currentSelectedType
            ? `${currentSelectedType}-${currentSelectedItemId}`
            : originPlanogramId
            ? `plan-${originPlanogramId}`
            : originRealogramCandidateId
            ? `actual-${originRealogramCandidateId}`
            : '';
        updateQueryParameter('compare', queryString);
      } else {
        removeQueryParameter('compare');
      }
    }
  };
  const resetState = () => {
    dispatch(setOpenOrientationMenu(false));
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateIsShowProductDetail(false));
    dispatch(updateSelectedRealogramItem(undefined));
    removeQueryParameter('item');
  };

  // TODO: Added browser back control processing
  useBrowserDialogBeforeUnload({ isDirty });

  return (
    <Box
      component="div"
      sx={{
        boxShadow: '0px 2px 8px 0px #0000000D',
        zIndex: 2,
        borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
      }}
    >
      <Header sx={{ background: '#fafafa' }}>
        <Box component="div" display="flex" alignItems="center" flexGrow={1}>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            overflow="hidden"
          >
            <Box component="div" overflow="hidden">
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitLineClamp: 1,
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitBoxOrient: 'vertical',
                  wordWrap: 'break-word',
                  fontSize: '16px',
                }}
              >
                {name}
              </Typography>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                gap={!bayPlanCode?.name ? 0 : bayPlanCodeGap}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    color: theme.palette.textBlack.secondary,
                  }}
                >
                  {bayPlanCode?.name ?? ''}
                </Typography>
                {storeBayPlan?.store && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: theme.palette.textBlack.secondary,
                    }}
                  >
                    {storeBayPlan.store.name}
                  </Typography>
                )}
              </Box>
            </Box>
            <Edit
              sx={{
                fill: isSwappingBayPartMode ? theme.palette.icons.disabled : '',
              }}
              onClick={handleEditPlanogramName}
            />
          </Box>
          <FormControl
            sx={{ minWidth: 200, ml: 3 }}
            disabled={isSwappingBayPartMode}
          >
            <InputLabel size="small">ステータス</InputLabel>
            <Select
              size="small"
              label="ステータス"
              value={organizationStatusId ?? ''}
              onChange={({ target: { value } }) => {
                handleChangeOrganizationStatusId(number().cast(value));
              }}
            >
              {data?.planogram_organization_statuses?.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          gap="12px"
          ml={2}
        >
          <ModeToggle
            onChange={handleChangeMode}
            value={mode}
            disabled={isSwappingBayPartMode}
          />
          <StyledButton
            type="contained"
            onClick={handleSave}
            disabled={isDisabledSaveButton || isSwappingBayPartMode}
          >
            保存
          </StyledButton>
          <StyledButton
            type="outlined"
            onClick={handleDoneClick}
            disabled={isSwappingBayPartMode}
          >
            完了
          </StyledButton>
        </Box>
      </Header>
      <PlanogramErrorDialog
        open={dialogOpen}
        handleClick={handleSavePlanogram}
        handleClose={() => setDialogOpen(false)}
        title={
          isFlatPlanogramError || isBucketExceedSize
            ? '商品または什器がゴンドラからはみ出ています。'
            : undefined
        }
        message={
          isFlatPlanogramError || isBucketExceedSize
            ? '商品または什器が配置可能エリアを超えています。このまま保存してよろしいですか？'
            : undefined
        }
      />
      <GoBackDialog
        open={goBackDialogOpen}
        handleCancel={() => setGoBackDialogOpen(false)}
        handleConfirm={() => {
          if (!id) return;
          navigate(paths.plans.planogramId(Number(id)));
        }}
      />
    </Box>
  );
};
