import { useEffect } from 'react';

type Props = {
  isDirty: boolean;
};

export const useBrowserDialogBeforeUnload = ({ isDirty }: Props) => {
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    // console.log('useBrowserDialogBeforeUnload::handleUnloadState');
    // NOTE: Cancel the event as stated by the standard.
    e.preventDefault();
  };
  useEffect(() => {
    // console.log('useBrowserDialogBeforeUnload::useEffect::mount', isDirty);
    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      // console.log('useBrowserDialogBeforeUnload::useEffect::unmount');
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);
};
