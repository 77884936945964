import { TableRowWrapper } from '@components/pages/storeBays/fragments/tableRowWrapper';
import { selectUser } from '@reducers/auth/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { ShelvesViewMode } from 'types/common';
import { RealogramDirectory, RealogramListOrder } from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { getColumns } from './directoryTableHeaderData';
import { TableCells } from './tableCells';
import { TableHeader } from './tableHeader';
import { DataTable } from '@components/organisms';
import { Link } from '@mui/material';
import { paths } from '@utils/const';

type Props = {
  isRefetching: boolean;
  realogramDirectories?: RealogramDirectory[];
  isFilteredByViewed: boolean;
  firstOrder?: RealogramListOrder;
  viewMode?: ShelvesViewMode;
  isDisplayLoadingSkeleton: boolean;
  isEmpty: boolean;
  emptyTableMessage: string;
  emptyTableTitle: string;
  handleRowClick: (index: number, item: RealogramDirectory) => void;
  handleOpenDeleteDialog: (id: number) => void;
  handleChangeOrder: (order?: RealogramListOrder) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleEndReached: (isAtBottom: boolean) => void;
  handleSharePermission: (item: RealogramSharePermissionProps) => void;
};

export const RealogramsDirectoryTable: FC<Props> = ({
  isRefetching,
  isEmpty,
  realogramDirectories,
  isFilteredByViewed,
  firstOrder,
  viewMode,
  isDisplayLoadingSkeleton,
  emptyTableMessage,
  emptyTableTitle,
  handleRowClick,
  handleOpenDeleteDialog,
  handleChangeOrder,
  handleFavoriteClick,
  handleEndReached,
  handleSharePermission,
}) => {
  const user = useAppSelector(selectUser);
  const columns = getColumns(isFilteredByViewed);

  return (
    <DataTable<RealogramDirectory>
      isRefetching={isRefetching}
      isEmpty={isEmpty}
      isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
      data={realogramDirectories}
      columns={columns}
      emptyTableMessage={emptyTableMessage}
      emptyTableTitle={emptyTableTitle}
      handleEndReached={handleEndReached}
      tableHeaderContent={
        <TableHeader
          columns={columns}
          firstOrder={firstOrder}
          handleChangeOrder={(order?: RealogramListOrder) =>
            handleChangeOrder(order)
          }
        />
      }
      itemContent={(_, item) => {
        const isStarred = item.favorite?.owner_id === user?.id;
        return (
          <TableCells
            viewMode={viewMode}
            isFilteredByViewed={false}
            realogramDirectory={item}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleFavoriteClick={() => handleFavoriteClick(item.id, isStarred)}
            handleSharePermission={() =>
              handleSharePermission({
                directoryId: item.id,
                type: item.type,
              })
            }
          />
        );
      }}
      renderTableRow={(props) => {
        const href =
          props.item.type === 'file' && props.item.realogram_candidate_id
            ? paths.actuals.id(String(props.item.realogram_candidate_id))
            : '';
        return (
          <TableRowWrapper
            component={props.item.type === 'file' ? Link : undefined}
            href={href}
            handleRowClick={() => {
              handleRowClick(
                props['data-index'],
                props.item as RealogramDirectory
              );
            }}
            {...props}
          />
        );
      }}
    />
  );
};
